<template>
  <div class="page">
    <div class="header" v-if="info && info.callInfo &&  info.callInfo.serviceOrderStatus === '已预约'">
      <div class="order_state">
        <img src="../assets/img/wait.png"></img>
        <div>等待接通</div>
      </div>
      <div class="pay_success">支付成功！平台将在{{info.callInfo.subscribeTime}}前为您接通医生</div>
      <!-- <div class="detail" wx:if="{{type}}">请尽快完善您的病情资料</div> -->
    </div>
    <div class="header" v-if="info && info.callInfo &&  info.callInfo.serviceOrderStatus === '电话通话成功'">
      <div class="order_state">
        <img src="../assets/img/success.png" />
        <div>完成通话</div>
      </div>
      <div class="pay_success">完成本次通话</div>
    </div>
    <div class="header" v-if="info && info.callInfo &&  info.callInfo.serviceOrderStatus === '电话医生拒绝'">
      <div class="order_state">
        <img src="../assets/img/refuse.png" />
        <div class="refuse_title">医生已拒绝</div>
      </div>
      <div class="refuse">医生已拒绝您的申请</div>
    </div>
    <div class="header" v-if="info && info.callInfo &&  info.callInfo.serviceOrderStatus === '电话连接失败'">
      <div class="order_state">
        <img src="../assets/img/tel_fail.png" />
        <div class="refuse_title">电话连接失败</div>
      </div>
      <div class="refuse">电话连接失败，请稍后重试</div>
    </div>
    <div class="header" v-if="info && info.callInfo && info.callInfo.serviceOrderStatus === '电话已取消'">
      <div class="order_state">
        <img src="../assets/img/tel_fail.png" />
        <div class="refuse_title">电话取消</div>
      </div>
      <div class="refuse">您的电话已取消</div>
    </div>
    <div class="describe" @click="onDetail">
      <div>病情描述</div>
      <div>
        <div>{{info.questionContent}}</div>
        <!-- <div>sdfsfsdfsdfsdfsdfsfs</div> -->
        <!-- </block> -->
      </div>
    </div>
    <div class="doctor">
      <img src="../assets/img/touxiang.png"></img>
      <div class="doctor_right">
        <div>您好，我是医生</div>
        <div>我将会使用<span class="phone">010</span>区号的固定电话与您联系</div>
        <div>请您保持电话畅通</div>
      </div>
    </div>
    <div class="footer">
      <div v-if="info && info.callInfo && info.callInfo.serviceTime">拨打时间： {{info.callInfo.serviceTime}}</div>
      <div>购买时长： {{info && info.callInfo && info.callInfo.buyDuration}}分钟</div>
      <div>消费金额： {{info.orderPrice}}元</div>
    </div>
  </div>
</template>
<script>
import { renderTime } from '../utils/util';
export default {
  data() {
    return {
      info: {}
    }
  },
  created() {
    this.telPhoneDetail(this.$route.query.orderId);
  },
  methods: {
    telPhoneDetail(id) {
      let data = {
        orderId: id
      };
      // request.post('cooperation/server/phone/detail/', data).then(res => {
      //   // console.log(res)
      //   this.info = res;
      //   this.$apply();
      // })
      this.$http('get', '/server/order/selectByOrderId', data, 1).then(res => {
        // console.log('电话详情', res)
        this.info = res.data.data;
        if (this.info.callInfo) {
          this.info.callInfo.subscribeTime = this.info.callInfo.subscribeTime.slice(10, 16);
          // this.info.questionContent = JSON.parse(this.info.questionContent);
          this.info.orderPrice = this.info.orderPrice.toFixed(2);
          if (this.info.callInfo.serviceTime) {
            this.info.callInfo.serviceTime = renderTime(this.info.callInfo.serviceTime);
          }
        }
      })
    },
    // onDetail
    onDetail() {
      this.$router.push({ path: '/tel_chating', query: { problem_id: this.info.orderId, serviceId: this.info.callInfo.serviceId } })
    }
  }
}
</script>

<style lang="less">
.page {
  min-height: 100%;
  background: #f8f8f8;
}
.header {
  background: #ffffff;
  text-align: center;
  .order_state {
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      margin-right: 0.06rem;
      width: 0.36rem;
      height: 0.36rem;
    }
    div {
      font-size: 0.3rem;
      color: #42cec8;
      margin: 0.18rem 0;
    }
    .refuse_title {
      color: rgb(251, 141, 130);
    }
  }
  .pay_success,
  .detail {
    font-size: 0.24rem;
    color: #42cec8;
    text-align: center;
    padding-bottom: 0.24rem;
  }
  .refuse {
    font-size: 0.24rem;
    color: rgb(251, 141, 130);
    text-align: center;
    padding-bottom: 0.24rem;
  }
  .detail {
    margin-top: 3px;
  }
}
.describe {
  margin: 0.14rem 0.22rem;
  background: #ffffff;
  border-radius: 0.04rem;
  padding: 0.24rem 0.38rem;
  div:first-child {
    color: #333333;
    font-size: 0.28rem;
    margin-bottom: 0.06rem;
  }
  div:last-child {
    color: #666666;
    font-size: 0.24rem;
  }
}
.doctor {
  display: flex;
  align-items: flex-start;
  padding: 0.32rem 0.22rem;
  background: #ffffff;
  img {
    width: 1.12rem;
    height: 1.12rem;
    margin-right: 0.22rem;
  }
  .doctor_right {
    border-radius: 0.12rem;
    border: 1px solid #42cec8;
    padding: 0.2rem 0.38rem;
    color: #333333;
    font-size: 0.24rem;
    .phone {
      color: #42cec8;
    }
  }
}
.footer {
  padding: 0.2rem;
  margin-top: 0.16rem;
  background: #ffffff;
  div {
    color: #666666;
    font-size: 0.24rem;
    margin-bottom: 3px;
  }
}
</style>